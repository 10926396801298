@import "@/assets/styles/global/lib.scss";






























































































































































































.popup-body {
  .markdown {
    display: inline-block;
    .p {
      font-size: size(12);
      line-height: size(17);
      padding-bottom: size(10);
      color: #000000;
    }
    em {
      font-style: italic;
      font-weight: normal;
    }
    strong {
      font-weight: bolder;
      em {
        font-weight: bolder;
      }
    }
    ul {
      list-style-type: disc;
      padding-left: size(20);
      padding-bottom: size(15);
      text-transform: capitalize;
      li {
        font-size: size(12);
        line-height: size(17);
        padding-top: size(6);
        color: #000000;
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
}
