@import "@/assets/styles/global/lib.scss";







































































































































































































































































































































































































































































































































































































































































































































































































































div {
  &.paygo {
    p {
      margin-bottom: size(65);
      display: inline-block;
    }
  }
  &.plan-box {
    .markdown {
      margin-bottom: size(65);
      display: inline-block;
      .p {
        font-size: size(12);
        line-height: size(17);
        padding-top: size(10);
        color: #000000;
      }
      em {
        font-style: italic;
        font-weight: normal;
      }
      strong {
        font-weight: bolder;
        em {
          font-weight: bolder;
        }
      }
      ul {
        list-style-type: disc;
        padding-left: size(20);
        li {
          font-size: size(12);
          line-height: size(17);
          padding-top: size(6);
          color: #000000;
        }
      }
    }
  }
}
.pricing-div {
  .package-price-wrapper {
    .standard-packages {
      .markdown {
        display: inline-block;
        em {
          font-style: italic;
          font-weight: normal;
        }
        strong {
          font-weight: bolder;
          em {
            font-weight: bolder;
          }
        }
        ul {
          li {
            font-size: size(14);
            line-height: size(24);
            padding-bottom: size(8);
            margin-bottom: size(8);
            padding-left: size(20);
            border-bottom: 1px solid #E2E2E2;
            color: #000000;
            position: relative;
            &:first-child {
              padding-top: 0;
            }
            &::before {
              content: url('~@/assets/icon-tick.svg');
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 0;
              top: size(12);
              transform: translateY(-50%);
            }
          }
        }
        &.list-view {
          padding-top: size(6);
          margin: 0;
          display: block;
          strong {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
