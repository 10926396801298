@import "@/assets/styles/global/lib.scss";
































.page-placeholder {
  --title-placeholder-width: #{size(320)};
  --placeholder-lines-height: #{size(20)};
  div[class*="placeholder"], li {
    background: var(--loading-placeholder-bg-lite);
    animation: placeholder-move .5s infinite;
  }

  .title-placeholder {
    height: size(124);
    margin-bottom: 15%;
    @media screen and (min-width: $breakpoint-md) {
      height: size(140);
    }
  }

  .placeholder-line {
    height: var(--placeholder-lines-height);
    margin: size(16) 0;
    width: 100%;
  }

  .descrition-block {
    max-width: var(--home-page-content-max-width);
    margin: 0 auto
  }

  .w-587 {
    height: size(75);
    @media screen and (min-width: $breakpoint-md) {
      width: size(587);
      height: size(151);
    }
  }

  .w-506 {
    height: size(35);
    @media screen and (min-width: $breakpoint-md) {
      width: size(506);
      height: size(73);
    }
  }

  .w-189 {
    display: inline-block;
    height: size(35);
    width: size(130);
    margin-right: 10px;
    margin-top: size(5);
    @media screen and (min-width: $breakpoint-md) {
      height: size(73);
      width: size(189);
      margin-top: size(46);
    }
  }
}

@keyframes placeholder-move {
  from {background-color: rgba(211, 215, 228, 0.5)}
  to {background-color: var(--loading-placeholder-bg-lite)}
}

